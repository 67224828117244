import styled from 'styled-components';

export const HeroBottom = styled.div`
    width: 100%;
    height: 120vh;
    position: absolute; 
    overflow: visible;
    left:  0;
    top: 0;

    .gatsby-image-wrapper {
      width: 100%;
      height: 120vh;
      
      @media (max-width: 1200px) {
        height: 100vh;
      }

      img {
        object-position: top !important;
      }
    }
`;

export const Anchor = styled.div`
    position: relative;
    height: 30vh;
`;

export const Hero = styled.div`

    position: relative;
    width: 100%;
    height: 100vh;
    line-height: 100px;
    font-size: 20px;
    font-family: Source-Light;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100vh;

      img {
        object-position: bottom !important;
      }
    }

    @keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
  }

  @keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    60% {
      opacity: .7;
      transform: translateX(0);
    }

    65% {
        opacity: 1;
    }
 
    70% {
      opacity: .5;
    }

    90% {
      opacity: .8;
    }

    100% {
      opacity: 1;
      transform: scale(1.15);
    }
  }

  height: 100vh;
  background-size: cover;
  position: relative;
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%); */

  .bottom {
    background-position: top;
    height: 120vh;
  }
  
  .header__text-box {
      position: absolute;
      width: 90%;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #F3F7F0;

      .header__desktop {
        transition: all .5s;
        line-height: 4rem;
        animation-delay: .5s;
        backface-visibility: hidden;

        .header__top {
          font-size: 15rem;
          letter-spacing: 3rem;
        }

        .header__middle {
          font-size: 3rem;
          font-family: Rubik;
          letter-spacing: 1.1rem;
        }

        .header__bottom {
          font-size: 2.5rem;
        }
      }

      @media only screen and (min-width: 808px) {
        .header__mobile {
          display: none; 
        }
      }
      
      @media only screen and (max-width: 808px) {
        .header__desktop {
          display: none; 
        }
      }

      .header__mobile {
        transition: all .5s;
        backface-visibility: hidden;

        .header__top {
          font-size: 3rem;
          letter-spacing: 0.37rem;
          line-height: 4rem;
        }

        .header__bottom {
          font-size: 2.2rem;
          letter-spacing: 0.1rem;
          line-height: 3rem;
        }
      }
      
      .header__top {
        font-family: 'Rubik', sans-serif;
        display: block;
    
        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;
      }

      .header__bottom {
        font-family: 'Source-Light', sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        letter-spacing: 0.5rem;
        animation: moveInRight 2.8s ease-out;
        transform: scale(1.15);
      }
  }
`;