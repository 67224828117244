import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { motion } from 'framer-motion';
import Loadable from '@loadable/component';

import styled from 'styled-components';

import { FaTags, FaEgg, FaHatWizard } from 'react-icons/fa';

import Container from 'components/ui/Container';

import * as Styled from './styles';

const Carousel = Loadable(() => import('components/ui/Carousel'));

const Egg = styled(FaEgg)`
  font-size: 40px;
`;

const Wizard = styled(FaHatWizard)`
  font-size: 40px;
`;



const PostsSample = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "blog section" } }) {
          frontmatter {
            title
            subtitle
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "blog" }, published: { eq: true } } }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              html
              fields {
                slug
              }
              frontmatter {
                title
                description
                topic
                date(formatString: "MMM DD, YYYY")
                tags
                level
                cover {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  `);

  const posts = allMarkdownRemark.edges.slice(0,6);
  
  return (
    <Container section pushup="true">
      <Styled.PostsSample>
      <Styled.Titles>
      <h2>Most recent blog posts</h2> 
      </Styled.Titles>
      <Carousel>
        {posts.map((item) => {
          const {
            id,
            fields: { slug },
            frontmatter: { title, cover, description, date, tags, level, topic }
          } = item.node;
          const levelIcon = level === '1' ? <Egg /> : level === '2' ? <Wizard /> : '';
          
          return (
            <Styled.Post key={id}>
              <Link to={slug}>
                <motion.div className="motion" whileHover={{ scale: 1.03 }} whiletap={{ scale: 1 }}>
                  <Styled.Card>
                    <Styled.Category className={topic}>
                      <h5>{topic}</h5>
                      <p>{levelIcon}</p>
                    </Styled.Category>
                    <Styled.Image>
                      <Img fluid={cover.childImageSharp.fluid} alt={title} />
                    </Styled.Image>
                    <Styled.Content>
                      <Styled.Title><span className={topic}>{title}</span></Styled.Title>
                      <Styled.Description><p>{description}</p></Styled.Description>
                      <time>{date}</time>
                      <Styled.Tags>
                        <FaTags />
                        {tags.map((item) => (
                          <Link key={item} to={`/tags/${item.toLowerCase()}`}>
                            <Styled.Tag >{item}</Styled.Tag>
                          </Link>
                        ))}
                      </Styled.Tags>
                    </Styled.Content>
                  </Styled.Card>
                </motion.div>
              </Link>
            </Styled.Post>
          );
        })}
        </Carousel>
      </Styled.PostsSample>
    </Container>
  );
};

export default PostsSample;
