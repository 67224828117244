import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Services from 'components/Services';
import HeroIndex from 'components/HeroIndex';
import PostsSample from 'components/PostsSample';
import { useStaticQuery, graphql } from 'gatsby';

const IndexPage: React.FC = () => {

  const { markdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "hero section" } }) {
          frontmatter {
            title
            subtitle
          }
        }
      }
  `);

  const subtitle = markdownRemark.frontmatter.subtitle;
  const title = markdownRemark.frontmatter.title;

  return (
    <Layout>
      <SEO description="I'm a full-stack growth marketer helping tech start-ups to scale. Welcome to my blog and portfolio."/>
      <HeroIndex title={title} subtitle={subtitle}/>
      <Services />
      <PostsSample />
    </Layout>
  );
};

export default IndexPage;
